export const COOKIES_KEYS = {
  accessToken: "token",
  email: "email",
  password: "password",
  elementId: "elementId",
  activationId: "activationId",
};

export const AUTHENTICATION_VALUES = {
  gitHubClientId: "Ov23liZVW62rVJ85W8Mb",
  gitHubScope: "user:email",
  gitHubState: `Math.random().toString(36).substring(2)`,
  socialAuthPlatform: "google",
};
