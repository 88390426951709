import { AUTHENTICATION_VALUES, ENDPOINTS } from "../utils";

export const useGithubLogin = () => {
  const handleGithubLogin = () => {
    const clientId = AUTHENTICATION_VALUES.gitHubClientId;
    const redirectUri = ENDPOINTS.logInWithGitHubRedirectUrl;
    const scope = AUTHENTICATION_VALUES.gitHubScope;
    const state = AUTHENTICATION_VALUES.gitHubState;

    const authUrl = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}&response_type=code`;

    window.location.href = authUrl;
  };

  return { handleGithubLogin };
};
