/* eslint-disable react/no-unknown-property */
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import "./OtpVerificationForm.css";
import Cookies from "js-cookie";
import { COOKIES_KEYS, ERRORMESSAGES } from "../../utils";
import { FormLoader, FormError } from "../form";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from "../../store/store";
import { useErrorMessage } from "../../hooks/useErrorMessage";
const OtpVerificationForm = ({ onSubmit, isLoading, isError }) => {
  const authStore = useStore((state) => state.setAuth);
  let navigate = useNavigate();
  const { error, handleError } = useErrorMessage();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  // // Watch the checkbox value
  const isChecked = watch("trust");

  const handleOtpSuccess = (body) => {
    Cookies.set(COOKIES_KEYS.accessToken, body.access_token, {
      expires: 0.00694,
    });
    authStore(true);
    navigate("/users");
  };

  const handleOtpError = (error) => {
    handleError(error);
  };

  return (
    <>
      <>
        <Grid>
          <Typography className="heading mb-3">Verify its you</Typography>
        </Grid>
        <p className="" style={{ color: "rgb(77, 91, 124)" }}>
          We sent a verification code to your email. Enter the code from the
          email in the field below.
        </p>
        <Grid>
          <form
            onSubmit={handleSubmit((data) =>
              onSubmit(data, handleOtpSuccess, handleOtpError),
            )}
          >
            <FormError isError={isError} error={error} />
            <Typography className="label label-required">
              6-digit code
            </Typography>

            <input
              className="textField textfieldDottoedLine"
              name="otp"
              type="number"
              placeholder="Enter 6 digit-code"
              {...register("otp", {
                required: ERRORMESSAGES.OtpValidationErrormsg.otp,
              })}
            />

            <span className="text-danger">
              {errors.otp && (
                <span className="text-danger">{errors.otp.message}</span>
              )}
            </span>

            <FormControlLabel
              className="pt-2 mt-2"
              control={
                <Checkbox
                  //   checked={checked}
                  //   onChange={handleChange}
                  {...register("trust")}
                  color="primary"
                  sx={{
                    color: isChecked ? "" : " rgb(91, 105, 135)", // Color when unchecked
                    "&.Mui-checked": {
                      color: "rgb(0, 105, 255)", // Color when checked
                    },
                  }}
                />
              }
              label="Trust this device for 60 days ?"
              sx={{
                display: "flex",
                alignItems: "center",
                color: isChecked ? "rgb(0, 97, 235)" : "rgb(3, 27, 78)",
              }}
            />

            <Button className="verify-button mt-2 d-flex" type="submit">
              Verify Code
            </Button>
            <FormLoader isLoading={isLoading} message="Otp verifying" />
          </form>
        </Grid>

        <Typography className="Need-help mt-4">Need Help?</Typography>
        <p className="py-1 mt-2" style={{ color: "rgb(77, 91, 124)" }}>
          check out our &nbsp;
          <Link
            className="troubleshoot"
            to=""
            sx={{
              cursor: "pointer",
              "&:hover": { textDecoration: "none" },
            }}
          >
            troubleshooting&nbsp;&nbsp;guide
          </Link>
          &nbsp;or &nbsp;
          <Link
            to="/"
            sx={{
              cursor: "pointer",
              "&:hover": { textDecoration: "none" },
            }}
          >
            try again
          </Link>
        </p>
      </>
    </>
  );
};

export default OtpVerificationForm;
