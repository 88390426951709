import { useEffect } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie"; // Assuming you're using js-cookie
import { useStore } from "../store/store"; // Adjust the import based on your state management
import { AUTHENTICATION_VALUES, COOKIES_KEYS } from "../utils"; // Adjust based on your constants
import { ENDPOINTS } from "../utils";
import { useSigninWithGoogleApi } from "../api/iam/apiHooks";

export const useGoogleAuth = (isSignUp) => {
  const location = useLocation();
  const navigate = useNavigate();
  const setAuthenticated = useStore((state) => state.setAuth);

  const { mutate: sendUserDataMutation, isLoading: isGoogleAuthLoading } =
    useSigninWithGoogleApi();

  const loginWithGoogle = useGoogleLogin({
    ux_mode: "redirect",
    redirectUri: ENDPOINTS.logInWithGoogleRedirectUrl,
    flow: "auth-code",
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");

    if (code) {
      const payload = {
        code,
        socialAuthPlatform: AUTHENTICATION_VALUES.socialAuthPlatform,
        signUp: isSignUp, // Use boolean directly
      };

      sendUserDataMutation(payload, {
        onSuccess: (body) => {
          Cookies.set(COOKIES_KEYS.accessToken, body.data.access_token, {
            expires: 0.00694,
          });
          Cookies.set(COOKIES_KEYS.email, body.data.email, {
            expires: 0.00694,
          });
          setAuthenticated(true);
          navigate("/Users");
        },
      });
    }
  }, [location, setAuthenticated, navigate, sendUserDataMutation, isSignUp]);

  return { loginWithGoogle, isGoogleAuthLoading };
};
