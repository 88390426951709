import axios from "axios";
import Cookies from "js-cookie";
const accessToken = Cookies.get("token");
const defaultHeaders = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
  },
};
export const getApiMethod = (url, params) => {
  return axios({
    ...defaultHeaders,
    url: url,
    method: "GET",
    params: params,
  });
};

export const postApiMethod = (
  url,
  body = null,
  params = null,
  headers = {},
) => {
  return axios({
    headers: {
      ...defaultHeaders.headers,
      ...headers,
    },
    url: url,
    method: "POST",
    data: body,
    params: params,
  });
};

export const putApiMethod = (url, body) => {
  return axios({
    ...defaultHeaders,
    url: url,
    method: "PUT",
    data: body,
  });
};
export const deleteApiMethod = (url, params) => {
  return axios({
    ...defaultHeaders,
    url: url,
    method: "DELETE",
    params: params,
  });
};
