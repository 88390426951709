import { useMutation, useQuery } from "react-query";
import {
  signInFunction,
  userActivationFunction,
  signUpFunction,
  getUsersData,
  resetPasswordFunction,
  signInWithGitHub,
  signInWithGoogle,
  getInviteUsersData,
  twoFactorAuthFunction,
  logoutFunction,
  getMyAccountProfile,
  passwordUpdateFunction,
  updateMyAccountProfile,
  getInviteSelectedUsers,
  usersStatus,
  getOrganizationData,
} from "./apiFunctions";

export const useSignUpApi = () => {
  const mutation = useMutation((body) => signUpFunction(body));
  return { ...mutation };
};

export const useSigninApi = () => {
  const mutation = useMutation((body) => signInFunction(body));
  return { ...mutation };
};
export const useLogoutApi = () => {
  const mutation = useMutation((body) => logoutFunction(body));
  return { ...mutation };
};
export const useUserActivattionApi = () => {
  const mutation = useMutation((body) => userActivationFunction(body));
  return { ...mutation };
};
export const useTwoFactorAuthApi = () => {
  const mutation = useMutation((body) => twoFactorAuthFunction(body));
  return { ...mutation };
};
export const useSigninWithGoogleApi = () => {
  const mutation = useMutation((body) => signInWithGoogle(body));
  return { ...mutation };
};
export const useSigninWithGitHubApi = () => {
  const mutation = useMutation((body) => signInWithGitHub(body));
  return { ...mutation };
};
export const useRestPassword = () => {
  const mutation = useMutation((body) => resetPasswordFunction(body));
  return { ...mutation };
};

export const useUsersList = (pageNumber, pageSize) => {
  return useQuery({
    queryKey: ["users-list", { pageNumber, pageSize }],
    queryFn: getUsersData,
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5, // Set stale time to 5 minutes (300000 milliseconds)
  });
};
export const useOrganizationList = () => {
  return useQuery({
    queryKey: ["Organization-list"],
    queryFn: getOrganizationData,
    keepPreviousData: true,
    staleTime: 1000 * 60 * 5, // Set stale time to 5 minutes (300000 milliseconds)
  });
};
export const useUsersInviteList = () => {
  const mutation = useMutation((body) => getInviteUsersData(body));
  return { ...mutation };
};
export const useInviteSelectedUsers = () => {
  const mutation = useMutation(({ email, id }) =>
    getInviteSelectedUsers({ email }, id),
  );

  return { ...mutation };
};
export const useMyAccountProfile = () => {
  return useQuery({
    queryKey: ["my-accountProfile"],
    queryFn: getMyAccountProfile,
    //keepPreviousData: true,
    //staleTime: 1000 * 60 * 5, // Set stale time to 5 minutes (300000 milliseconds)
  });
};

export const usePasswordUpdateApi = () => {
  const mutation = useMutation((body) => passwordUpdateFunction(body));
  return { ...mutation };
};

export const useUpdateMyAccountProfile = () => {
  const mutation = useMutation((body) => updateMyAccountProfile(body));
  return { ...mutation };
};
export const useUsersStatus = () => {
  const mutation = useMutation((payload) => usersStatus(payload));
  return { ...mutation };
};
