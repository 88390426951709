//Localhost
const BASE_URL = "http://localhost:3050/api/v1";
const BASE_REDIRECT_URL = "http://localhost:3000/";
const BASE_CALLBACK_URL = "http://localhost:3000/auth/callback";

// //Production
// const BASE_URL = "https://service-iam.smet.in/api/v1/iam";
// const BASE_REDIRECT_URL = "http://localhost:3000/";
// const BASE_CALLBACK_URL = "http://localhost:3000/auth/callback";

export const ENDPOINTS = {
  getUsers: `${BASE_URL}/account/usersList`,
  register: `${BASE_URL}/account/register`,
  userActivation: `${BASE_URL}/account/register/activation`,
  authenticate: `${BASE_URL}/account/authenticate`,
  logout: `${BASE_URL}/account/logout`,
  twoFactorAuth: `${BASE_URL}/account/authenticate/2fa`,
  verifyOtp: `${BASE_URL}/account/verifyOtp`,
  forgotPassword: `${BASE_URL}/account/forgot/password`,
  signInWithGoogle: `${BASE_URL}/account/authenticate`,
  logInWithGoogleRedirectUrl: `${BASE_REDIRECT_URL}`,
  logInWithGitHubRedirectUrl: `${BASE_CALLBACK_URL}`,
  myAccountProfile: `${BASE_URL}/account`,
  changePassword: `${BASE_URL}/account/password`,
  updateProfileName: `${BASE_URL}/account/profile`,
  userInvitation: `${BASE_URL}/site/user/invitation`,
  usersStatus: `${BASE_URL}/site/user`,
  organization: `${BASE_URL}/site/organizations`,
};
