import React from "react";
import { Button, Grid } from "@mui/material";
import "./ForgotPassword.css";
import LoginHeader from "../login/components/loginHeader";
import { useForm } from "react-hook-form";
import { ERRORMESSAGES, validateEmail } from "../../utils";
import { FormLoader, FormError } from "../../components/form";
import { useRestPassword } from "../../api/iam/apiHooks";
import { useNavigate } from "react-router";
import { useErrorMessage } from "../../hooks/useErrorMessage";
function ForgotPassword() {
  let navigate = useNavigate();
  const { error, handleError } = useErrorMessage();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    mutate: forgotPasswordMutation,
    isLoading,
    isError,
  } = useRestPassword();

  const onSubmit = (data) => {
    let payload = {
      email: data.email,
    };
    forgotPasswordMutation(payload, {
      onSuccess: () => {
        navigate("/", {
          state: {
            message: "We have emailed you a link to reset your password",
          },
        });
      },
      onError: (error) => {
        handleError(error);
      },
    });
  };

  return (
    <Grid>
      <LoginHeader signupText="Don't have an account?" signinText="Sign Up" />
      <Grid className="bgImage">
        <Grid className="cardStyle1">
          <Grid className="cardDesign">
            <Grid className="cardfix ">
              <h3
                style={{
                  fontSize: "32px",
                  letterSpacing: "-.5px",
                  lineHeight: "40px",
                  fontWeight: "700",
                }}
              >
                Forgot Password?
              </h3>
              <p style={{ color: "rgb(77, 91, 124)" }}>
                Enter the email address associated with your account and we will
                send you a link to reset your password.
              </p>
              <FormError isError={isError} error={error} />
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid className="d-grid gap-3">
                  <Grid>
                    <label className="labelStyle">
                      email address
                      <span className="text-danger" style={{ font: "1rem" }}>
                        *
                      </span>
                    </label>
                    <Grid>
                      <input
                        className="inputStyles textfieldDottoedLine"
                        placeholder="Enter your email address"
                        {...register("email", {
                          required: ERRORMESSAGES.loginPageErrormsg.email,
                          validate: {
                            validEmail: (value) =>
                              validateEmail(value) ||
                              ERRORMESSAGES.loginPageErrormsg.validEmail,
                          },
                        })}
                      />
                      {errors.email && (
                        <span className="text-danger">
                          {errors.email.message}
                        </span>
                      )}
                    </Grid>
                  </Grid>

                  <Grid>
                    <Button
                      className="buttonStyle"
                      type="submit"
                      disabled={isLoading}
                    >
                      Request Password Reset
                    </Button>
                    <FormLoader
                      isLoading={isLoading}
                      message="Emailing you a link"
                    />
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ForgotPassword;
