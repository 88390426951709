import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../SignUp.css";
import SocialAuthButtons from "../../../components/commonButtons/SocialAuthButtons";
import GoogleLogo from "../../../assets/Google-Logo.png";
import GithubLogo from "../../../assets/github-icon.png";
import Loader from "../../dashBoard/components/loader/Loader";
import LoginHeader from "../../login/components/loginHeader";
import { useGoogleAuth, useGithubLogin } from "../../../hooks";

const CreateAccount = () => {
  let navigate = useNavigate();
  const isSignup = true;

  const { loginWithGoogle, isGoogleAuthLoading } = useGoogleAuth(isSignup);

  const { handleGithubLogin } = useGithubLogin();
  return (
    <>
      {isGoogleAuthLoading ? (
        <Loader />
      ) : (
        <Grid>
          <LoginHeader
            signupText="Already have an account?"
            signinText="Sign In"
          />
          <Grid className="bgImage">
            <Grid className="cardStyle1">
              <Grid className="cardDesign">
                <Grid className="cardfix ">
                  <h3
                    style={{
                      fontSize: "32px",
                      letterSpacing: "-.5px",
                      lineHeight: "40px",
                      fontWeight: "700",
                    }}
                  >
                    Create your account
                  </h3>
                  <p
                    className="mt-3"
                    style={{ color: "rgb(77, 91, 124)", marginTop: "0px" }}
                  >
                    Start spending more time on your projects and less time
                    managing your infrastructure.
                  </p>
                  <SocialAuthButtons
                    loginWithSocialMedia={loginWithGoogle}
                    name="Sign In with Google"
                    imgLogo={GoogleLogo}
                  />
                  <SocialAuthButtons
                    loginWithSocialMedia={handleGithubLogin}
                    name="Sign In with GitHub"
                    imgLogo={GithubLogo}
                  />
                  <Button
                    className="signUpButton"
                    type="submit"
                    onClick={() => navigate("/signUp")}
                  >
                    Sign Up with Email
                  </Button>
                </Grid>
              </Grid>
              <Grid
                className="d-flex justify-content-center"
                sx={{ position: "relative", top: "1.5rem" }}
              >
                <Typography sx={{ fontSize: "14px" }}>
                  By signing up you agree to the &nbsp;
                  <Link
                    to=""
                    className="linkHover"
                    sx={{
                      cursor: "pointer",
                      color: "inherit",
                    }}
                  >
                    Terms of Service
                  </Link>
                  &nbsp;and &nbsp;
                  <Link
                    to=""
                    className="linkHover"
                    sx={{ cursor: "pointer", color: "inherit" }}
                  >
                    Privacy Policy
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CreateAccount;
