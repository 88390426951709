import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useStore } from "../store/store";
import Loader from "../../src/pages/dashBoard/components/loader/Loader";
import { COOKIES_KEYS } from "../../src/utils";
import { useNavigate } from "react-router-dom";
import { useSigninWithGitHubApi } from "../api/iam/apiHooks";

const Callback = () => {
  const authStore = useStore((state) => state.setAuth);
  let history = useNavigate();

  const { mutate: authenticateUser, isLoading } = useSigninWithGitHubApi();

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get("code");
    if (code) {
      let payload = {
        socialAuthPlatform: "github",
        code: code,
      };
      authenticateUser(payload, {
        onSuccess: (body) => {
          Cookies.set(COOKIES_KEYS.accessToken, body.data.access_token, {
            expires: 0.00694,
          });
          Cookies.set(COOKIES_KEYS.email, body.data.email, {
            expires: 0.00694,
          });

          authStore(true);
          history("/");
        },
        onError: (error) => console.log(error),
      });
    } else {
      console.error("No code found in the URL, redirecting to login.");
      history("/");
    }
  }, [authenticateUser, history, authStore]);
  return (
    <>
      {" "}
      {isLoading && (
        <div>
          <Loader />
        </div>
      )}
    </>
  );
};

export default Callback;
