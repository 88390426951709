import { Button, Divider, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Link } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import { validatePassword } from "../../../../utils/helpers";
import { usePasswordUpdateApi } from "../../../../api/iam/apiHooks";
import { FormError, FormLoader } from "../../../../components/form";
import LinearProgress from "@mui/material/LinearProgress";
import "./Password.css";
import { CustomInput } from "../../../../components/customInput/CustomInput";
import { ERRORMESSAGES } from "../../../../utils";
import CustomEyeIcon from "../../../../components/customEyeIcon/CustomEyeIcon";

const Password = () => {
  const [editpassword, setEditPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState({
    text: "",
    error: false,
    focusValid: false,
    passwordVisible: false,
  });
  const [newPassword, setNewPassword] = useState({
    text: "",
    error: false,
    focusValid: false,
    passwordVisible: false,
  });
  const [confirmNewPassword, setConfirmNewPassword] = useState({
    text: "",
    error: false,
    focusValid: false,
    passwordVisible: false,
  });

  const [inValidPwd, setInValidPwd] = useState(false);

  const {
    mutateAsync: passwordUpdateMutate,
    isLoading,
    isError,
    error,
  } = usePasswordUpdateApi();

  const validateInputs = () => {
    if (
      currentPassword.text === "" ||
      newPassword.text === "" ||
      confirmNewPassword.text === ""
    ) {
      setCurrentPassword({
        ...currentPassword,
        focusValid: true,
      });
      setNewPassword({
        ...newPassword,
        focusValid: true,
      });
      setConfirmNewPassword({
        ...confirmNewPassword,
        focusValid: true,
      });
      return;
    }
    if (!validatePassword(newPassword.text)) {
      setInValidPwd(true);
      return;
    }

    if (newPassword.text !== confirmNewPassword.text) {
      setInValidPwd(false);
      return;
    }

    if (
      currentPassword.text !== "" &&
      newPassword.text !== "" &&
      confirmNewPassword.text !== ""
    ) {
      setInValidPwd(false);
      let payload = {
        current_password: currentPassword.text,
        new_password: confirmNewPassword.text,
      };
      updatePassword(payload);
    }
  };

  const updatePassword = async (payload) => {
    try {
      await passwordUpdateMutate(payload);
      cancelResetFields();
    } catch (_) {
      //handle onError if needed
    }
  };

  const cancelResetFields = () => {
    setEditPassword(false);
    setCurrentPassword({
      text: "",
      error: false,
      focusValid: false,
    });
    setNewPassword({
      text: "",
      error: false,
      focusValid: false,
    });
    setConfirmNewPassword({
      text: "",
      error: false,
      focusValid: false,
    });
  };

  return (
    <div>
      <Grid className="row">
        <Grid className="col-lg-3">
          <Typography sx={{ fontWeight: 600 }}>Password</Typography>
        </Grid>
        <Grid
          className="col-lg-7"
          sx={{ position: "relative", bottom: editpassword ? "3rem" : "" }}
        >
          <Typography>
            {!editpassword ? (
              "•••••••••••••"
            ) : (
              <>
                <br />
                <Grid className="row">
                  <Grid className="col-lg-6">
                    {isError ? (
                      <FormError isError={isError} error={error} />
                    ) : null}
                    <br />

                    <CustomInput
                      gridclassName={
                        currentPassword.text === "" &&
                        currentPassword.focusValid
                          ? "password-dotter-line  password-errorTextField"
                          : "password-ProfileDottoedLine"
                      }
                      labelMandatoryStyle={{
                        color:
                          currentPassword.text === "" &&
                          currentPassword.focusValid
                            ? "rgb(202, 12, 12)"
                            : "rgb(51, 135, 255)",
                      }}
                      labeltext="Enter current password"
                      labelMandatory={true}
                      type={
                        currentPassword.passwordVisible ? "text" : "password"
                      }
                      placeholder="Enter current password"
                      value={currentPassword.text}
                      onFocus={() =>
                        setCurrentPassword({
                          ...currentPassword,
                          focusValid: true,
                        })
                      }
                      focusValid={currentPassword.focusValid}
                      onChange={(e) => {
                        setCurrentPassword({
                          ...currentPassword,
                          text: e.target.value,
                          error: true,
                        });
                      }}
                      className={"common-password-field "}
                      suffixIcon={
                        <CustomEyeIcon
                          onClick={() =>
                            setCurrentPassword({
                              ...currentPassword,
                              passwordVisible: !currentPassword.passwordVisible,
                            })
                          }
                          passwordShowIcon={true}
                          type={
                            currentPassword.passwordVisible
                              ? "text"
                              : "password"
                          }
                        />
                      }
                      error={
                        currentPassword.text === "" &&
                        currentPassword.focusValid
                          ? ERRORMESSAGES.changePasswordErrorMsg.Required
                          : null
                      }
                    />
                  </Grid>
                </Grid>

                <Grid className="row mt-3">
                  <Grid className="col-lg-6">
                    <CustomInput
                      gridclassName={
                        newPassword.text === "" && newPassword.focusValid
                          ? "password-dotter-line  password-errorTextField"
                          : "password-ProfileDottoedLine"
                      }
                      labelMandatoryStyle={{
                        color:
                          newPassword.text === "" && newPassword.focusValid
                            ? "rgb(202, 12, 12)"
                            : "rgb(51, 135, 255)",
                      }}
                      labeltext="Enter new password"
                      labelMandatory={true}
                      type={newPassword.passwordVisible ? "text" : "password"}
                      placeholder="Enter new password"
                      value={newPassword.text}
                      onFocus={() =>
                        setNewPassword({
                          ...newPassword,
                          focusValid: true,
                        })
                      }
                      focusValid={newPassword.focusValid}
                      onChange={(e) => {
                        setNewPassword({
                          ...newPassword,
                          text: e.target.value,
                          error: true,
                        });
                      }}
                      className={"common-password-field "}
                      eyeIcon={
                        <CustomEyeIcon
                          onClick={() =>
                            setNewPassword({
                              ...newPassword,
                              passwordVisible: !newPassword.passwordVisible,
                            })
                          }
                          passwordShowIcon={false}
                          type={
                            newPassword.passwordVisible ? "text" : "password"
                          }
                        />
                      }
                      error={
                        newPassword.text === "" && newPassword.focusValid
                          ? ERRORMESSAGES.changePasswordErrorMsg.Required
                          : null
                      }
                    />
                  </Grid>

                  <Grid className="col-lg-6">
                    <CustomInput
                      gridclassName={
                        confirmNewPassword.text === "" &&
                        confirmNewPassword.focusValid
                          ? "password-dotter-line  password-errorTextField"
                          : "password-ProfileDottoedLine"
                      }
                      labelMandatoryStyle={{
                        color:
                          confirmNewPassword.text === "" &&
                          confirmNewPassword.focusValid
                            ? "rgb(202, 12, 12)"
                            : "rgb(51, 135, 255)",
                      }}
                      labeltext="Enter new password"
                      labelMandatory={true}
                      type={
                        confirmNewPassword.passwordVisible ? "text" : "password"
                      }
                      placeholder="Enter new password"
                      value={confirmNewPassword.text}
                      onFocus={() =>
                        setConfirmNewPassword({
                          ...confirmNewPassword,
                          focusValid: true,
                        })
                      }
                      focusValid={confirmNewPassword.focusValid}
                      onChange={(e) => {
                        setConfirmNewPassword({
                          ...confirmNewPassword,
                          text: e.target.value,
                          error: true,
                        });
                      }}
                      className={"common-password-field "}
                      suffixIcon={
                        <CustomEyeIcon
                          onClick={() =>
                            setConfirmNewPassword({
                              ...confirmNewPassword,
                              passwordVisible:
                                !confirmNewPassword.passwordVisible,
                            })
                          }
                          passwordShowIcon={true}
                          type={
                            confirmNewPassword.passwordVisible
                              ? "text"
                              : "password"
                          }
                        />
                      }
                      error={
                        confirmNewPassword.text === "" &&
                        confirmNewPassword.focusValid
                          ? ERRORMESSAGES.changePasswordErrorMsg
                              .confirmPasswordRequired
                          : confirmNewPassword.text !== "" &&
                              confirmNewPassword.focusValid &&
                              newPassword.text !== confirmNewPassword.text
                            ? ERRORMESSAGES.changePasswordErrorMsg
                                .passwordInValid
                            : null
                      }
                    />
                  </Grid>
                </Grid>

                <Grid className="mt-3">
                  {inValidPwd ? (
                    <Grid sx={{ color: "red" }}>
                      <ErrorIcon />
                      {ERRORMESSAGES.loginPageErrormsg.passwordValid}
                    </Grid>
                  ) : null}
                </Grid>
                <Grid>
                  <LinearProgress
                    className="passwordBorder"
                    color={
                      validatePassword(newPassword.text)
                        ? "success"
                        : newPassword.text !== "" &&
                            newPassword.text.length >= 6
                          ? "warning"
                          : "inherit"
                    }
                    variant="determinate"
                    value={
                      validatePassword(newPassword.text)
                        ? 100
                        : newPassword.text !== "" &&
                            newPassword.text.length >= 6
                          ? 50
                          : 0
                    }
                  />
                </Grid>
                <label
                  style={{ fontSize: "16px", fontWeight: 600, color: "031b4e" }}
                >
                  Password requirement
                </label>
                <Grid>
                  <ClearIcon
                    fontSize="small"
                    sx={{ color: "rgb(244, 72, 72)" }}
                  />
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "031b4e",
                    }}
                  >
                    Must be at least 8 characters long
                  </label>
                </Grid>
                <Grid>
                  <ClearIcon
                    fontSize="small"
                    sx={{ color: "rgb(244, 72, 72)" }}
                  />
                  <label
                    style={{
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "031b4e",
                    }}
                  >
                    Must contain a capital letter, a small letter, number and
                    special character
                  </label>
                </Grid>
                <Grid className="d-flex justify-content-start">
                  {isLoading ? (
                    <FormLoader
                      isLoading={isLoading}
                      message="Updating Password"
                    />
                  ) : null}
                </Grid>
                <Grid className="mt-3">
                  <Button
                    className="profileBtn"
                    disabled={
                      newPassword.text !== confirmNewPassword.text
                        ? true
                        : currentPassword.text === ""
                          ? true
                          : newPassword.text === ""
                            ? true
                            : confirmNewPassword.text === ""
                              ? true
                              : !validatePassword(newPassword.text)
                                ? true
                                : false
                    }
                    sx={{
                      opacity:
                        newPassword.text !== confirmNewPassword.text
                          ? 0.5
                          : currentPassword.text === ""
                            ? 0.5
                            : newPassword.text === ""
                              ? 0.5
                              : confirmNewPassword.text === ""
                                ? 0.5
                                : !validatePassword(newPassword.text)
                                  ? 0.5
                                  : "",
                      textTransform: "capitalize",
                    }}
                    onClick={validateInputs}
                  >
                    Update Password
                  </Button>
                  <Link
                    onClick={cancelResetFields}
                    className="profileLinkCancelHover px-3 "
                  >
                    Cancel
                  </Link>
                </Grid>
              </>
            )}
          </Typography>
        </Grid>
        <Grid className="col-lg-2 d-flex justify-content-end">
          {editpassword ? null : (
            <Button className="btnStyle" onClick={() => setEditPassword(true)}>
              Reset Password
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid className="py-3">
        <Divider sx={{ borderColor: "gray" }} />
      </Grid>
    </div>
  );
};

export default Password;
